import { Box, Stack, Typography } from '@mui/material'
import { Container } from '@mui/system'
import TopLinkButton from 'components/organisms/member/LinkButton'
import { getLayout } from 'layouts/AnonymousLayout'
import MemberHead from 'components/MemberHead'

const Custom404 = () => (
  <>
    <MemberHead title="404 Not Found" />
    <Box mt={2}>
      <Container>
        <Typography textAlign="center" fontSize={24}>
          404 Not Found
        </Typography>
        <Typography textAlign="center" mt={2}>
          ページが見つかりませんでした
        </Typography>
        <Typography textAlign="center" mt={1}>
          お探しのページはURLが正しくないか、ページが削除された可能性があります。
        </Typography>
        <Stack
          mt={3}
          justifyContent="center"
          direction="column"
          alignItems="center"
        >
          <TopLinkButton>トップページへ</TopLinkButton>
        </Stack>
      </Container>
    </Box>
  </>
)

Custom404.getLayout = getLayout
export default Custom404
