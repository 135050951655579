import { useCallback, useMemo, useState } from 'react'
import { parse, serialize, SerializeOptions } from 'cookie'

export const useCookie = (): CookieStates => {
  const [cookies, setCookies] =
    useState<Record<string, string | undefined>>(parseDocumentCookie)
  const setCookie = useCallback(
    (name: string, value: string, option?: SerializeOptions) => {
      setDocumentCookie(name, value, option)
      setCookies(parseDocumentCookie())
    },
    []
  )
  const removeCookie = useCallback(
    (name: string, option?: SerializeOptions) => {
      setCookie(name, '', { ...option, maxAge: -1 })
    },
    [setCookie]
  )
  return { cookies, setCookie, removeCookie }
}

export type CookieStates = {
  cookies: Record<string, string | undefined>
  setCookie: (name: string, value: string, option?: SerializeOptions) => void
  removeCookie: (name: string, option?: SerializeOptions) => void
}

export const useCookieValue = <V extends string>(
  name: string,
  defaultValue: V,
  option?: SerializeOptions
): CookieValueStates<V> => {
  const resolvedOptions = useMemo(() => withDefaultOptions(option), [option])
  const [value, setValue] = useState(() => {
    const cookies = parseDocumentCookie()
    return (cookies[name] as V | undefined) ?? defaultValue
  })
  const setCookieValue = useCallback(
    (value: V) => {
      setValue(value)
      setDocumentCookie(name, value, resolvedOptions)
    },
    [name, resolvedOptions]
  )
  const removeCookieValue = useCallback(() => {
    setValue(defaultValue)
    setDocumentCookie(name, '', { ...resolvedOptions, maxAge: -1 })
  }, [defaultValue, name, resolvedOptions])
  return [value, setCookieValue, removeCookieValue]
}

export type CookieValueStates<V> = [
  value: V,
  setCookie: (value: V) => void,
  remove: () => void,
]

function withDefaultOptions(options?: SerializeOptions): SerializeOptions {
  return {
    path: '/',
    secure: true,
    sameSite: 'lax',
    domain: process.env.NEXT_PUBLIC_FRONT_BASE_DOMAIN,
    ...options,
  }
}

function setDocumentCookie(
  name: string,
  value: string,
  option?: SerializeOptions
) {
  document.cookie = serialize(name, value, option)
}

function parseDocumentCookie(): Record<string, string | undefined> {
  return parse(document.cookie)
}
