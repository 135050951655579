import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { Failure, Result, Success } from 'apis/result'
import snakecaseKeys from 'snakecase-keys'

export const requestApi = async <T = void, D = Record<string, unknown>>(
  method: Method,
  path: string,
  data?: D,
  headers?: Record<string, string>,
  autoHandlesError = true
): Promise<Result<T>> => {
  const url = `/survey/api/${path}`
  const dataConfig = method === 'GET' ? { params: data } : { data }
  try {
    const response = await axiosInstance.request<T>({
      url,
      method,
      headers,
      ...dataConfig,
    })
    return new Success(response.data)
  } catch (error) {
    console.error(error)
    const failure = Failure.of(error)
    if (autoHandlesError) failure.handleError().catch(console.error)
    return failure
  }
}

export const apiUrlOf = (path: string, query?: Record<string, string>) => {
  const url = `/survey/api/${path}`
  let queryString = ''
  if (query) {
    const queryParams = new URLSearchParams(snakecaseKeys(query))
    queryString = `?${queryParams.toString()}`
  }
  return `${axiosInstance.defaults.baseURL}${url}${queryString}`
}

export const hasCsrfToken = () =>
  !!axiosInstance.defaults.headers.common['X-CSRF-Token']

export const csrfToken = () =>
  hasCsrfToken()
    ? (axiosInstance.defaults.headers.common['X-CSRF-Token'] as string)
    : ''

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})
axiosInstance.interceptors.request.use((req: InternalAxiosRequestConfig) => {
  if (req.data && !(req.data instanceof FormData)) {
    req.data = snakecaseKeys(req.data)
  } else if (req.params) {
    req.params = snakecaseKeys(req.params)
  }
  return req
})
axiosInstance.interceptors.response.use((res: AxiosResponse) => {
  if (res.headers['x-csrf-token']) {
    axiosInstance.defaults.headers.common['X-CSRF-Token'] =
      res.headers['x-csrf-token']
  }
  return res
})

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
