import Head from 'next/head'
import { FC, useMemo } from 'react'

type Props = {
  title: string
}
const MemberHead: FC<Props> = ({ title }) => {
  const fullTitle = useMemo(
    () => `${title} | バヅクリエンゲージメント`,
    [title]
  )
  return (
    <Head>
      <title>{fullTitle}</title>
    </Head>
  )
}
export default MemberHead
