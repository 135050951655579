import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useRouter } from 'next/compat/router'
import { LocaleCode, showsLocaleSetting, SUPPORT_LOCALES } from 'lib/locale'
import { localeApi } from 'apis/sessions/localeApi'
import { useCookieValue } from 'hooks/useCookie'
import * as Sentry from '@sentry/nextjs'
import { usePathname } from 'next/navigation'

const LocaleContext = createContext<LocaleContextType<string>>({
  localeCode: 'ja',
  localeOptions: [],
  t: () => {
    console.error('LocaleContextがprovideされていません。')
    return ''
  },
})

export const LocaleContextProvider: FC<{
  children: ReactNode
  defaultLocaleCode: LocaleCode | null
}> = ({ defaultLocaleCode, children }) => {
  const { localeCode, localeOptions, translator } =
    useLocaleContextProviderStates(defaultLocaleCode)
  return (
    <LocaleContext.Provider
      value={{ localeCode, localeOptions, t: translator }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export const useI18n = <K extends string>(): LocaleContextType<K> =>
  useContext(LocaleContext)

const useLocaleContextProviderStates = (
  defaultLocaleCode: LocaleCode | null
) => {
  const router = useRouter()
  const [cookieLocale, setCookieLocale] = useCookieValue<LocaleCode>(
    'locale',
    showsLocaleSetting ? (defaultLocaleCode ?? 'ja') : 'ja'
  )
  const localeOptions = useMemo(() => {
    return SUPPORT_LOCALES.map((locale) => ({
      label: locale.label,
      selected: locale.code === cookieLocale,
      handleChange: async () => {
        setCookieLocale(locale.code)
        await localeApi
          .update({ code: locale.code })
          .catch(Sentry.captureException)
        router?.reload()
      },
    }))
  }, [cookieLocale, router, setCookieLocale])
  const translator = useTranslator(cookieLocale)
  return { localeCode: cookieLocale, localeOptions, translator }
}

const useTranslator = (locale: LocaleCode): Translator<string> => {
  const pathname = usePathname()
  const [dictionary, setDictionary] = useState<Record<string, string>>()
  useEffect(() => {
    if (pathname) {
      const localePath = `${pathname}${locale}`.replace(/^\//, '')
      import(`locales/pages/${localePath}`)
        .then((module) => setDictionary(module.default))
        .catch(() => {
          /* まだ未対応の画面が多いためエラーハンドリングはしない */
        })
    }
  }, [locale, pathname])
  return useCallback(
    (key: string) => {
      if (dictionary === undefined) return ''
      const expression = dictionary[key]
      if (expression === undefined)
        console.warn(`expression[${key}] is not found.`)
      return expression ?? ''
    },
    [dictionary]
  )
}

type LocaleContextType<K> = {
  localeCode: LocaleCode
  localeOptions: LocaleOption[]
  t: Translator<K>
}
export type LocaleOption = {
  label: string
  selected: boolean
  handleChange: () => Promise<void>
}
export type Translator<K> = (key: K) => string
